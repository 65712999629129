import { ref, computed, watch, onMounted } from '@vue/composition-api'
import { useHolidays } from './useHolidays'
import { capitalize } from '@/utils/filter'
import { avatarText } from '@/utils/filter'
import { getUserData } from '../../../utils/utils'

import useAPI from '@/utils/useAPI'
import SearchFilter from '../../../components/searchFilterV2/SearchFilter'
import i18n from '@/libs/i18n'
import ability from '../../../libs/acl/ability'
import router from '@/router'

export const mixinHolidays = {
  components: {
    SearchFilter,
  },
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const sortOptions = ref([
      {
        text: capitalize(i18n.tc('waiting for validation')),
        key: '_filter_isWaitingForValidation',
        selected: true
      },
      {
        text: capitalize(i18n.tc('validated')),
        key: '_filter_isValidated',
      },
      {
        text: capitalize(i18n.tc('declined')),
        key: '_filter_isRefused',
      },
    ])

    const columns = ref([
      {
        display: capitalize(i18n.tc('employee')),
        key: '_employee',
        sortable: true
      },
      {
        display: capitalize(i18n.t('start')),
        key: 'startDate',
        sortable: true,
        isDate: true
      },
      {
        display: capitalize(i18n.t('end')),
        key: 'endDate',
        sortable: true,
        isDate: true
      },
      {
        display: capitalize(i18n.t('status')),
        key: 'status',
        sortable: true
      },
    ])

    const currentHoliday = ref(null)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { events } = useAPI()

    const holidays = computed(() => {
      return events.value.filter(e => {
        if (ability.can('manage', 'all')) {
          return e.type == 'holiday'
        } else {
          return e.type == 'holiday' && e.logs[0].by.id == getUserData().id
        }
      })
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const {
      fetchEvents,
    } = useAPI()

    const {
      submitValidatedHoliday,
      removeHoliday,
    } = useHolidays()

    const resetCurrentHoliday = () => {
      currentHoliday.value = null
    }

    const validateHoliday = (holiday) => {
      currentHoliday.value = JSON.parse(JSON.stringify(holiday))
      currentHoliday.value.isValidated = true

      submitValidatedHoliday(currentHoliday.value)
        .then(() => {
          resetCurrentHoliday()
        })
    }

    const declineHoliday = (holiday) => {
      currentHoliday.value = JSON.parse(JSON.stringify(holiday))
      currentHoliday.value.isValidated = false

      submitValidatedHoliday(currentHoliday.value)
        .then(() => {
          resetCurrentHoliday()
        })
    }

    const removeHolidayLocal = (holiday) => {
      removeHoliday(holiday)
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    fetchEvents({start: null, end: null}, 'holiday')

    return {
      // Components
      capitalize,
      avatarText,

      // Data
      sortOptions,
      columns,

      // Computed
      holidays,

      // Methods
      validateHoliday,
      declineHoliday,
      removeHolidayLocal
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    removeHolidayAlert (employee) {
      this.$bvModal
        .msgBoxConfirm(this.$t('DeleteAlert', { msg: this.$t('theHoliday') }), {
          okVariant: 'danger',
          okTitle: this.capitalize(this.$t('delete')),
          cancelVariant: 'outline-secondary',
          cancelTitle: this.capitalize(this.$t('cancel')),
          centered: true,
        })
        .then(value => {
          if (value == true) {
            this.removeHolidayLocal(employee)
          }
        })
    }
  },
  mounted () {
  },
  created () {
  }
}